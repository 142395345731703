import { useLocation } from 'react-router-dom';
import { useMemo, useEffect } from 'react';

const useWatchURLSearchParams = (callback) => {
    const location = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
    useEffect(() => {
      callback(searchParams);
    }, [callback, searchParams]);
  };

  export default useWatchURLSearchParams;
