import { Add, Delete, FormatPaint } from '@mui/icons-material';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import AddCircleButton from 'components/addCircleButton/addCircleButton';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import GenericModal from 'components/genericModal/genericModal';
import GozioTable from 'components/tables/gozioTable';
import WarningPanel from 'components/warningPanel/warningPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import { getTableCountTitle } from 'helpers/table-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import { useDeleteKiosk, useKiosks } from 'hooks/dataHooks/useKiosks';
import { useKioskTemplate } from 'hooks/dataHooks/useKioskTemplate';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import ColorPalette from 'pages/gozio_colors';
import AddKioskModal from 'pages/kiosks/containers/addKioskModal';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import React, { useContext, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const buildStyles = () => ({
  addButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    width: '36px',
    height: '36px',
    zIndex: 3,
  },
  content: { height: 'calc(100vh - 188px)', position: 'relative' },
});

const getKioskColumns = (networkId, navigate) => [
  {
    Header: 'ID',
    accessor: 'id',
    alwaysHidden: true,
  },
  {
    Header: 'Kiosk Name',
    accessor: 'name',
    minWidth: 150,
    width: 150,
    onClick: (data) => {
      navigate(`/network/${networkId}/kiosks/${data.id}`);
    },
    sticky: 'left',
  },
  {
    Header: 'Progress',
    accessor: 'progress',
    minWidth: 114,
    width: 114,
  },
  {
    Header: 'Site Name',
    accessor: 'site',
    minWidth: 161,
    width: 161,
  },
  {
    Header: 'Building Name',
    accessor: 'building',
    minWidth: 161,
    width: 161,
  },
  {
    Header: 'Floor Name',
    accessor: 'floor',
    minWidth: 161,
    width: 161,
  },
  {
    Header: 'Assigned ID',
    accessor: 'assignedId',
    minWidth: 130,
    width: 130,
    maxWidth: 130,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    minWidth: 75,
    width: 75,
    disableSortBy: true,
    justifyRight: true,
  },
];

const KiosksPageContent = React.memo(({ networkId }) => {
  const styles = buildStyles();
  const { handleNavigate } = useContext(FlamingoContext);
  const isUberAdmin = useCheckGozioAdmin();
  const [showAdd, setShowAdd] = useState(false);
  const [deletedKiosk, setDeletedKiosk] = useState(null);

  const { data: { count, kiosks } } = useKiosks();
  const { data: templateData } = useKioskTemplate();

  const [deleteKiosk] = useDeleteKiosk();
  const { activeNetwork } = useContext(FlamingoContext);
  const kioskEnabled = !!activeNetwork?.kioskEnabled;

  const processKiosks = () => kiosks.map((k) => ({
      id: k?.id,
      progress: capitalize(k?.progress || 'incomplete'),
      assignedId: k?.assignedId,
      name: k?.name,
      site: getLabelByLang(k?.site?.name),
      building: getLabelByLang(k?.building?.name),
      floor: getLabelByLang(k?.floor?.name),
      actions: (
        <Box className="hoverUnhide">
          <Tooltip title="Delete">
            <Delete onClick={() => setDeletedKiosk(k)} />
          </Tooltip>
        </Box>
      ),
    }));

  const memoizedData = useMemo(() => processKiosks(), [kiosks]); // eslint-disable-line react-hooks/exhaustive-deps

  const memoizedKioskColumns = useMemo(() => {
    const columns = getKioskColumns(networkId, handleNavigate);
    if (!isUberAdmin) {
      columns.pop();
    }
    return columns;
  }, [isUberAdmin, networkId, handleNavigate]);

  const renderContent = () => {
    if (count === 0) {
      return (
        <EmptyPanel
          title="There Are No Kiosks Set Up"
          subtitle="Start by creating a Kiosk and format the Kiosk's Template."
          buttonLabel={'Add a Kiosk'}
          ButtonIcon={Add}
          buttonShown
          buttonClicked={() => {
            setShowAdd(true);
          }}
        />
      );
    }

    return (
      <>
        {count > 0 && isUberAdmin && (
          <AddCircleButton
            onClick={() => setShowAdd(true)}
            aria-label="add-category"
            sx={styles.addButton}
            tooltipTitle="Add a Kiosk"
          />
        )}
        <GozioTable
          name="KiosksTables"
          columns={memoizedKioskColumns}
          data={memoizedData}
          sortBy={[{ id: 'name', desc: false }]}
          sx={{
            borderRadius: '20px',
            boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
              ColorPalette.grey[600],
              0.4,
            )}`,
          }}
          countTitle={getTableCountTitle(count, 'Kiosk')}
        />
      </>
    );
  };

  const handleKioskAdded = (data) => {
    setShowAdd(false);
    if (data?.id) {
      handleNavigate(`/network/${networkId}/kiosks/${data.id}`);
    }
  };

  const handleDeleteKiosk = () => {
    deleteKiosk({ variables: { id: deletedKiosk.id } });
    setDeletedKiosk(null);
  };

  if (!kioskEnabled) {
    return <Navigate to={`/network/${networkId}/welcome`} />;
  }

  return (
    <FlamingoPage
      pageName="Kiosks"
      headerButtons={
        <Grid container>
          {templateData?.progress === 'incomplete' && (
            <WarningPanel
              message={'Kiosk Template is incomplete.'}
              disableClose={true}
              sx={{ width: 'auto', marginRight: '20px' }}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNavigate(`/network/${networkId}/kiosks/template?tab=0`)
            }
            startIcon={<FormatPaint />}
          >
            Format Kiosk Template
          </Button>
        </Grid>
      }
    >
      <Box sx={styles.content}>{renderContent()}</Box>
      {showAdd && (
        <AddKioskModal
          handleClose={() => setShowAdd(false)}
          handleConfirm={handleKioskAdded}
        />
      )}
      {deletedKiosk && (
        <GenericModal
          title="Delete Kiosk"
          body={`Are you sure ${deletedKiosk.name} is no longer in use and you want to proceed with deleting it?`}
          open={!!deletedKiosk}
          handleClose={() => setDeletedKiosk(null)}
          handleConfirm={handleDeleteKiosk}
          confirmText="Delete Kiosk"
          customTags={{
            confirm: 'KioskDeleteModalConfirmButton',
            cancel: 'KioskDeleteModalCancelButton',
          }}
        />
      )}
    </FlamingoPage>
  );
});

const KiosksPage = React.memo((props) => {
  const { networkId } = useParams();

  return (
    <LoggedinLayout enableSuspense={true} supportedWorkspace={LIVE_WORKSPACE}>
      <KiosksPageContent networkId={networkId} {...props} />
    </LoggedinLayout>
  );
});

export default React.memo(KiosksPage);
