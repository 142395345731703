import { useMemo } from 'react';
import { useCustomQuery } from 'hooks/dataHooks/useCustomQuery';
import { FIND_NETWORK_VISIBILITY_RULE } from 'graphql/queries';

export const useFindNetworkVisibilityRule = () => useCustomQuery(FIND_NETWORK_VISIBILITY_RULE, {
  dataFn: useMemo(() => (data) => data?.findNetworkVisibilityRule?.edges.map(({ node }) => node), []),
  initialState: [],
  skipFn: (data) => data?.edges?.length > 0,
  suspense: true,
});
