import { gql } from '@apollo/client';

export const CREATE_NETWORK_NOTIFICATION = gql`
  mutation createNetworkNotification($input: NetworkNotificationInputCreate!) {
    createNetworkNotification(input: $input) {
      id
      createdAt
      title
      type
    }
  }
`;

export const GET_NETWORK_NOTIFICATIONS = gql`
  query getNetwork($networkId: ID!) {
    getNetwork(id: $networkId) {
      notifications {
        id
        title
        type
        createdAt
        mobileApp {
          id
          name
        }
        visibilityRule {
          id
          name
        }
        visibilityRuleCustom
        geofence {
          latitude
          longitude
          radius
          type
        }
      }
    }
  }
`;
