import { Box, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GozioDrawer from 'components/drawer/drawer';
import FormWatcher from 'components/forms/formWatcher';
import { colorWithAlpha } from 'helpers/color-util';
import { buildLabelByLang, getLabelByLang, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { useUpdateNavigationItem } from 'hooks/dataHooks/useNavigation';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import useToast from 'hooks/useToast';
import { uploadImage } from 'modules/media';
import { makeValidate } from 'mui-rff';
import { getBottomNavLinkSchema, MORE_MENU_LINKS } from 'pages/mobileSettings/bottomNavigation/bottomNavigationHelper';
import { AboutUsGeneralTabPanel } from 'pages/mobileSettings/bottomNavigation/containers/AboutUsGeneralTabPanel';
import { AboutUsPageTabPanel } from 'pages/mobileSettings/bottomNavigation/containers/AboutUsPageTabPanel';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

        '& .MuiPaper-root': {
            height: '100%',
            overflowX: 'hidden',
        },
    },
    content: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    fieldArea: {
        marginTop: theme.spacing(3.25),
    },
    formFields: {
        marginTop: theme.spacing(3.25),
    },
    field: {
        marginBottom: theme.spacing(4),
    },
    fieldSection: {
        marginBottom: theme.spacing(4),
    },
    tabs: {
        padding: '0 24px',
        boxShadow: `0px 4px 4px -3px ${colorWithAlpha(
            theme.palette.grey[600],
            0.3,
        )}`,
        height: '68px',
        position: 'absolute',
        top: 0,
        width: '100%',
        background: theme.palette.white,
        zIndex: 10,
    },
    tabPanel: {
        width: '100%',
        padding: '67px 24px 0 24px',
        height: '100%',
        overflow: 'scroll',
    },
}));

export const TAB_I18N_FIELDS = [
    {
        key: 'name',
        lang: LANGUAGE_CODES.ENGLISH,
        langKey: 'nameEn',
        label: 'Bottom Navigation Link Name',
        required: true,
        charLimit: 10,
    },
    {
        key: 'name',
        lang: LANGUAGE_CODES.SPANISH,
        langKey: 'nameEs',
        label: 'Bottom Navigation Link Name (Spanish)',
        required: false,
        charLimit: 10,
    },
];

const buildInitialValues = (data, languages) => {
    const values = {
        data: data?.data,
        langES: hasSpanish(languages),
        iconUrl: data?.icon?.url || null,
        nameEn: getLabelByLang(data?.name),
        nameEs: getLabelByLang(data?.name, LANGUAGE_CODES.SPANISH),
        supportingImageUrlEn: data?.supportingImage?.en?.url || null,
        supportingImageUrlEs: data?.supportingImage?.es?.url || null,
        supportingImageAccessibilityTextEn: getLabelByLang(data?.supportingImageAccessibilityText) || null,
        supportingImageAccessibilityTextEs: getLabelByLang(data?.supportingImageAccessibilityText, LANGUAGE_CODES.SPANISH) || null,
        eulaUrl: data?.legalInfo?.eulaUrl || null,
        privacyPolicyUrl: data?.legalInfo?.privacyPolicyUrl || null,
        legalNotesUrl: data?.legalInfo?.legalNotesUrl || null,
    };

    return values;
};

const EditAboutUsPanel = ({
                              data,
                              languages,
                              navId,
                              onEditCardCancelled,
                              onEditCardChanged,
                              onEditCardSaved,
                              onTabValueChanged,
                              title: incomingTitle,
                          }) => {
    const classes = useStyles();
    const validate = makeValidate(
        getBottomNavLinkSchema(MORE_MENU_LINKS, languages),
    );

    const isUberAdmin = useCheckGozioAdmin();

    const [title] = useState(incomingTitle);
    const [tabValue, setTabValue] = useState(0);
    const [icon, setIcon] = useState(null);
    const [supportingImageEn, setSupportingImageEn] = useState(null);
    const [supportingImageEs, setSupportingImageEs] = useState(null);
    const [initialValues, setInitialValues] = useState({});

    const { networkId } = useParams();
    const { toastNotificationErrorHook, toastNotificationSuccessHook }
        = useToast();
    const [updateAboutUsLink] = useUpdateNavigationItem(networkId);

    useEffect(() => {
        if (data && Object.keys(initialValues).length === 0) {
            setInitialValues(buildInitialValues(data, languages));
        }
    }, [data, languages, initialValues]);

    const onSubmit = async (values = {}) => {
        setIsSubmitting(true);
        const updatedData = {
            id: data?.id,
            name: buildLabelByLang(values, 'nameEn', 'nameEs'),
            order: data?.order,
            type: values.type || undefined,
            legalInfo: {
                eulaUrl: values.eulaUrl ?? '',
                privacyPolicyUrl: values.privacyPolicyUrl ?? '',
                legalNotesUrl: values.legalNotesUrl ?? '',
            },
            icon: data?.icon?.image?.id,
            supportingImage: {
                en: data?.supportingImage?.en?.id,
                es: data?.supportingImage?.es?.id,
            },
            supportingImageAccessibilityText: values?.supportingImageAccessibilityTextEn?.trim() || values?.supportingImageAccessibilityTextEs?.trim()
                ? buildLabelByLang(
                    {
                        ...values,
                        supportingImageAccessibilityTextEn: values.supportingImageAccessibilityTextEn?.trim() || undefined,
                        supportingImageAccessibilityTextEs: values.supportingImageAccessibilityTextEs?.trim() || undefined,
                    },
                    'supportingImageAccessibilityTextEn',
                    'supportingImageAccessibilityTextEs'
                )
            : null,
        };

        for (const { image, lang, field } of [
            { image: icon, field: 'icon' },
            {
                image: supportingImageEn,
                lang: LANGUAGE_CODES.ENGLISH,
                field: 'supportingImage',
            },
            {
                image: supportingImageEs,
                lang: LANGUAGE_CODES.SPANISH,
                field: 'supportingImage',
            },
        ]) {
            if (image?.file) {
                const uploadedImage = await uploadImage(
                    image.file,
                    toastNotificationErrorHook,
                );
                if (lang) {
                    updatedData[field][lang] = uploadedImage?.id;
                } else {
                    updatedData[field] = uploadedImage?.id;
                }
            }
        }

        const result = await updateAboutUsLink({
            variables: {
                id: navId,
                input: updatedData,
            },
        });

        setIcon(null);
        setSupportingImageEn(null);
        setSupportingImageEs(null);
        if (result.data) {
            toastNotificationSuccessHook(
                <>
                    Your progress on the About Page
                    <strong style={{ marginLeft: '4px', marginRight: '4px' }}>
                        {values.nameEn}
                    </strong>
                    has been saved.
                </>,
            );

            const dataKey = 'items';
            const resultKey = isGlobalTemplate(networkId) ? 'System' : 'Network';
            const list = result.data[`set${resultKey}NavigationItem`]
                ? result.data[`set${resultKey}NavigationItem`][dataKey]
                : result.data[`set${resultKey}NavigationTab`][dataKey];
            const tabOrItem = data?.id
                ? list.find((n) => n.id === data.id)
                : list[list.length - 1];
            setInitialValues(buildInitialValues(tabOrItem, languages));
            onEditCardSaved(tabOrItem, values);
        }
        setIsSubmitting(false);
    };

    const buildAboutUsValues = ({
                                    form,
                                    iconUrl = null,
                                    supportingImageUrlEn,
                                    supportingImageUrlEs,
                                }) => {
        const { nameEn, nameEs, supportingImageAccessibilityTextEn, supportingImageAccessibilityTextEs, ...rest } = form.getState().values;

        return {
            id: data.id,
            name: buildLabelByLang({ nameEn, nameEs }, 'nameEn', 'nameEs'),
            icon: iconUrl ? { url: iconUrl } : data.icon,
            supportingImage: {
                en: supportingImageUrlEn
                    ? { url: supportingImageUrlEn }
                    : data?.supportingImage?.en,
                es: supportingImageUrlEs
                    ? { url: supportingImageUrlEs }
                    : data?.supportingImage?.es,
            },
            supportingImageAccessibilityText: buildLabelByLang({ supportingImageAccessibilityTextEn, supportingImageAccessibilityTextEs }, 'supportingImageAccessibilityTextEn', 'supportingImageAccessibilityTextEs'),
            ...rest,
        };
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const handleConfirm = async (form) => {
        await form.submit();
        await form.reset();
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={data.id ? validate : null}
        >
            {({
                  handleSubmit,
                  form,
                  values,
                  submitting,
                  pristine,
                  invalid,
                  errors,
              }) => (
                <form
                    className={classes.root}
                    onSubmit={handleSubmit}
                    onChange={() => onEditCardChanged(buildAboutUsValues({ form }))}
                >
                    <FormWatcher
                        formRenderProps={{ form }}
                        errorFormFieldMap={
                            new Map([
                                [
                                    'nameEn',
                                    {
                                        label: 'More Menu Link Name',
                                        section: 'General',
                                    },
                                ],
                                [
                                    'nameEs',
                                    {
                                        label: 'More Menu Link Name (Spanish)',
                                        section: 'General',
                                    },
                                ],
                                [
                                    'supportingImageAccessibilityTextEn',
                                    {
                                        label: 'Accessibility Description',
                                        section: 'About Page',
                                    },
                                ],
                                [
                                    'supportingImageAccessibilityTextEs',
                                    {
                                        label: 'Accessibility Description',
                                        section: 'About Page',
                                    },
                                ],
                                [
                                    'eulaUrl',
                                    {
                                        label: 'EULA URL',
                                        section: 'About Page',
                                    },
                                ],
                                [
                                    'privacyPolicyUrl',
                                    {
                                        label: 'Privacy Policy URL',
                                        section: 'About Page',
                                    },
                                ],
                                [
                                    'legalNotesUrl',
                                    {
                                        label: 'Legal Notes URL',
                                        section: 'About Page',
                                    },
                                ],
                            ])
                        }
                        isClosing={isClosing}
                        isSubmitting={isSubmitting}
                        onClose={() => {
                            setIsClosing(false);
                            setIsSubmitting(false);
                        }}
                        onContinue={onEditCardCancelled}
                    />

                    <GozioDrawer
                        open={true}
                        disableCancel={true}
                        greyOutConfirm={pristine || submitting || invalid}
                        onClose={() => setIsClosing(true)}
                        onConfirm={() => handleConfirm(form)}
                        title={title}
                        top={70}
                        width={568}
                    >
                        <Box className={classes.content}>
                            <Box className={classes.tabs}>
                                <Tabs
                                    value={tabValue}
                                    onChange={(e, idx) => {
                                        setTabValue(idx);
                                        onTabValueChanged(idx);
                                    }}
                                    aria-label="edit about us tabs"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab
                                        key="tab_0"
                                        label="GENERAL"
                                        {...tabA11yProps('edit-about-us', 0)}
                                    />
                                    ,
                                    <Tab
                                        key="tab_1"
                                        label="ABOUT PAGE"
                                        {...tabA11yProps('edit-about-us', 1)}
                                    />
                                    ,
                                </Tabs>
                            </Box>
                            {tabValue === 0 && (
                                <AboutUsGeneralTabPanel
                                    classes={classes}
                                    index={0}
                                    languages={languages}
                                    networkId={networkId}
                                    onIconUploaded={({ file, url }) => {
                                        setIcon({ file, iconUrl: url });
                                        onEditCardChanged(
                                            buildAboutUsValues({ form, iconUrl: url }),
                                        );
                                    }}
                                    tabValue={tabValue}
                                />
                            )}
                            {tabValue === 1 && (
                                <AboutUsPageTabPanel
                                    classes={classes}
                                    index={1}
                                    isUberAdmin={isUberAdmin}
                                    languages={languages}
                                    onImageEnUploaded={({ file, url }) => {
                                        setSupportingImageEn({ file, iconUrl: url });
                                        onEditCardChanged(
                                            buildAboutUsValues({ form, supportingImageUrlEn: url }),
                                        );
                                    }}
                                    onImageEsUploaded={({ file, url }) => {
                                        setSupportingImageEs({ file, iconUrl: url });
                                        onEditCardChanged(
                                            buildAboutUsValues({ form, supportingImageUrlEs: url }),
                                        );
                                    }}
                                    tabValue={tabValue}
                                />
                            )}
                        </Box>
                    </GozioDrawer>
                </form>
            )}
        </Form>
    );
};

EditAboutUsPanel.propTypes = {
    navId: PropTypes.string,
    data: PropTypes.object.isRequired,
    onEditCardCancelled: PropTypes.func.isRequired,
    onEditCardChanged: PropTypes.func,
    onEditCardSaved: PropTypes.func.isRequired,
    onTabValueChanged: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

EditAboutUsPanel.defaultProps = {
    navId: null,
    onEditCardChanged: () => {
    },
};

export default EditAboutUsPanel;
