import { gql } from '@apollo/client';

export const FIND_NETWORK_VISIBILITY_RULE = gql`
  query findNetworkVisibilityRule {
    findNetworkVisibilityRule {
      edges {
        node {
          id
          name
          description
          network {
            name
          }
          rule
        }
      }
    }
  }
`;
