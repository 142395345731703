import FormErrorsModal from 'components/forms/formErrorsModal';
import UnsavedModal from 'components/forms/unsavedModal';
import { getQueryObjectFromString } from 'helpers/url-util';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlocker } from 'react-router-dom';

const MODAL_STATES = {
  NONE: 'none',
  ERROR: 'error',
  UNSAVED_CHANGES: 'unsavedChanges',
};

export const FormWatcher = (props) => {
  const {
    allowContinueOnError,
    continueAndDeleteText,
    errorFormFieldMap,
    formRenderProps,
    isClosing,
    onClose,
    onClosed,
    onContinue,
    onCorrect,
    onFormChanged,
    unsavedConfirmText,
    watchURLSearchParams,
  } = props;

  const [modalState, setModalState] = useState(MODAL_STATES.NONE);

  const { form } = formRenderProps;
  const { dirty, dirtySinceLastSubmit, errors, hasValidationErrors, submitSucceeded } = form.getState();
  const isDirty = useMemo(() => dirty && (dirtySinceLastSubmit || (!dirtySinceLastSubmit && !submitSucceeded)),
      [dirty, dirtySinceLastSubmit, submitSucceeded]
  );

  const hasPathnameChanged = useCallback(
    ({ currentLocation, nextLocation }) => (currentLocation.pathname !== nextLocation.pathname) || (watchURLSearchParams && currentLocation.search !== nextLocation.search),
    [watchURLSearchParams]
  );
  const hasWorkspaceChanged = ({ currentLocation, nextLocation }) => {
    const currentQueryObject = getQueryObjectFromString(currentLocation.search);
    const nextQueryObject = getQueryObjectFromString(nextLocation.search);
    const hasWorkspaceParam = currentQueryObject.workspace !== undefined && nextQueryObject.workspace !== undefined;
    const workspaceChanged = currentQueryObject.workspace !== nextQueryObject.workspace;
    return hasWorkspaceParam && workspaceChanged;
  };
  const shouldBlockNavigation = useCallback(({ currentLocation, nextLocation }) => {
    const isNavigating
      = hasPathnameChanged({ currentLocation, nextLocation })
      || hasWorkspaceChanged({ currentLocation, nextLocation });
    const shouldBlock = isDirty || hasValidationErrors;
    return isNavigating && shouldBlock;
  }, [hasPathnameChanged, hasValidationErrors, isDirty]);

  const blocker = useBlocker(shouldBlockNavigation);
  const isBlocked = useMemo(() => blocker.state === 'blocked', [blocker]);

  useEffect(() => {
    if (dirty && onFormChanged) {
      onFormChanged({ dirty, dirtySinceLastSubmit, errors, hasValidationErrors, submitSucceeded });
    }
    if ((isClosing || isBlocked) && hasValidationErrors) setModalState(MODAL_STATES.ERROR);
    else if ((isClosing || isBlocked) && !hasValidationErrors && isDirty) setModalState(MODAL_STATES.UNSAVED_CHANGES);
    else if (isClosing && !isBlocked) {
      if (onClosed) onClosed({ dirty, errors });
      setModalState(MODAL_STATES.NONE);
    }
  }, [
    dirty,
    dirtySinceLastSubmit,
    errors,
    hasValidationErrors,
    onFormChanged,
    submitSucceeded,
    isClosing,
    isDirty,
    isBlocked,
    onClosed,
  ]);

// FormErrorsModal onCancel = Continue Button --> onContinue
// FormErrorsModal onClose = X or Correct Errors Button --> onCorrect
// UnsavedModal onClose = X or Cancel Button --> onClose
// UnsavedModal onContinue = Continue & Delete Changes Button --> onContinue
// UnsavedModal onSave = Confirm Button

const proceedWithNavigation = useCallback(() => {
  setModalState(MODAL_STATES.NONE);
  if (isBlocked) {
    blocker.proceed();
    blocker.reset();
  }
}, [blocker, isBlocked]);

const resetBlocker = useCallback(() => {
  setModalState(MODAL_STATES.NONE);
  if (isBlocked) blocker.reset();
}, [blocker, isBlocked]);

return (
    <>
      {modalState === MODAL_STATES.ERROR && (
        <FormErrorsModal
          disableCancel={!allowContinueOnError}
          open={true}
          onCancel={async () => {
            form.reset();
            if (onContinue) onContinue({ form });
            proceedWithNavigation();
          }}
          onClose={() => {
            resetBlocker();
            if (onCorrect) onCorrect({ form });
          }}
          formFieldMap={errorFormFieldMap}
        />
      )}
      {modalState === MODAL_STATES.UNSAVED_CHANGES && (
        <UnsavedModal
          open={true}
          confirmText={unsavedConfirmText}
          continueAndDeleteText={continueAndDeleteText}
          onClose={() => {
            resetBlocker();
            if (onClose) onClose({ form });
          }}
          onContinue={async () => {
            proceedWithNavigation();
            form.reset();
            if (onContinue) onContinue({ form });
          }}
          onSave={async () => {
            await form.submit();
            proceedWithNavigation();
          }}
        />
      )}
    </>
  );
};

export default React.memo(FormWatcher);
